import { createFeatureSelector, createSelector } from '@ngrx/store'
import { AuthStateInterface } from '../interfaces/authState.interface'

export const authFeatureSelector =
    createFeatureSelector<AuthStateInterface>('auth')

export const currentUserSelector = createSelector(
    authFeatureSelector,
    (authState: AuthStateInterface) => authState.currentUser
)

export const userPreferencesSelector = createSelector(
    authFeatureSelector,
    (authState: AuthStateInterface) => authState.userPreferences
)

export const isUserLoggedInSelector = createSelector(
    authFeatureSelector,
    (authState: AuthStateInterface) => !!authState?.currentUser !== null
)

export const isAdminUserSelector = createSelector(
    authFeatureSelector,
    (authState: AuthStateInterface) => authState?.currentUser?.isAdmin
)